import { Link } from "react-router-dom";
import { DarkButton } from "../UI/Button/button";
import Categories from "../UI/Categories/categories.jsx";
import { useCategories } from "../../context/categoryContext.js";
import { useUser } from "../../context/userContext.js";
import { useEffect, useRef, useState } from "react";
import { useCities } from "../../context/citiesContext.js";
import axios from "../../axios.js";

const CategorySearch = () => {
  const { categories } = useCategories();

  const { user, setUser } = useUser();
  const { cities } = useCities();

  const [cityInput, setCityInput] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [showCityDropdown, setShowCityDropdown] = useState(false);
  const cityInputRef = useRef(null);

  useEffect(() => {
    if (user && user.setCitySearch) {
      setCityInput(user.setCitySearch);
    }
  }, [user]);

  const handleCityInputChange = (e) => {
    const value = e.target.value;
    setCityInput(value);

    if (value.length > 0) {
      const filtered = cities.filter((city) =>
        city.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredCities(filtered);
      setShowCityDropdown(filtered.length > 0);
    } else {
      setFilteredCities([]);
      setShowCityDropdown(false);
    }
  };

  const handleCitySelect = (selectedCity) => {
    setCityInput(selectedCity);
    if (user && user.telegramId) {
      axios
        .patch("/selectcity", {
          telegramId: user.telegramId,
          setCitySearch: selectedCity,
        })
        .then(() => {
          setUser((prevUser) => ({ ...prevUser, setCitySearch: selectedCity }));
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setShowCityDropdown(false);
  };

  return (
    <div className="py-[84px] flex flex-col gap-[40px] items-center">
      {user?.role === "artist" ? (
        <h2 className="text-[24px] font-semibold">Каталог артистов</h2>
      ) : (
        <div className="flex flex-col text-center px-[12px] gap-[25px]">
          <h2 className="text-[24px] font-semibold">Кого вы ищете?</h2>
          <p className="text-[18px] leading-normal">
            Выберите категорию артиста или создайте заявку для вашего события
          </p>
        </div>
      )}
      <div className="relative mt-[-24px]" ref={cityInputRef}>
        <input
          type="text"
          value={cityInput}
          onChange={handleCityInputChange}
          placeholder="Выберите город"
          className="text-[16px] underline bg-white text-center"
        />
        {showCityDropdown && (
          <ul className="absolute border border-gray-300 w-full max-h-60 overflow-y-auto z-20 text-[12px]">
            {filteredCities.map((city, index) => (
              <li
                key={city + index}
                onClick={() => handleCitySelect(city)}
                className="px-2 py-1 hover:bg-gray-100 cursor-pointer bg-white"
              >
                {city}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="flex flex-col items-center w-full px-[12px]">
        <Categories categories={categories} />
        {user?.role === "customer" && (
          <Link to="/add-application" className="w-full">
            <DarkButton text={"Создать заявку"} />
          </Link>
        )}
      </div>
    </div>
  );
};

export default CategorySearch;
